import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import { useParams, generatePath } from 'react-router';
// import Common from '../Common/common';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import InfiniteScroll from "react-infinite-scroller";
registerLocale('ja', ja);

const pageCount = 100;

const CustomerReceipt = (props) => {
  const { siteId } = useParams();
  const { setting } = props;
  const [receiptData, setReceiptData] = useState(null);
  const [shopMaster, setShopMaster] = useState(null);
  const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const [purchaseStartDate, setPurchaseStartDate] = useState('');
  const [purchaseEndDate, setPurchaseEndDate] = useState('');
  const [cashierNo, setCashierNo] = useState('');
  const [receiptNo, setReceiptNo] = useState('');
  const [shopId, setShopId] = useState({});
  const [customerId, setCustomerId] = useState('');
  const [scanStartDate, setScanStartDate] = useState(moment().add(-1, 'days').startOf('day').toDate());
  const [scanEndDate, setScanEndDate] = useState('');
  const [invalidFlag, setInvalidFlag] = useState({});
  const [invalidCause, setInvalidCause] = useState({});
  const [searchFlag, setSearchFlag] = useState(true);
  // const [errMsg, setErrMsg] = useState({ postalCode: '' });
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
  const [page, setPage] = useState(0);

  const createCsvData = useCallback((data, master) => {
    const list = [];
    const head = [
      { label: 'お客様No', key: 'customer_id' },
      { label: '店舗名', key: 'shop_name' },
      { label: '店舗コード', key: 'shop_code' },
      { label: '購入日', key: 'purchase_date' },
      { label: 'レジNo', key: 'cashier_no' },
      { label: 'レシートNo', key: 'receipt_no' },
      { label: '読込日時 ', key: 'scan_date' },
      { label: '獲得ポイント', key: 'point' },
      { label: '金額', key: 'amount' },
      { label: '無効フラグ', key: 'invalid_flag' },
      { label: '無効理由', key: 'invalid_cause' },
      { label: '無効日時', key: 'invalid_date' },
    ];
    data.forEach(r => {
      var receipt = {
        ...r,
        shop_name: getShop(r, master).shop_name,
        shop_code: getShop(r, master).shop_code,
        scan_date: r.scan_date ? moment(r.scan_date).format("YYYY/MM/DD HH:mm:ss") : '',
        point: r.t_points ? r.t_points.point : '',
        invalid_flag: r.invalid_flag === constClass.INVALID_FLAG.UNCONFIRM ? constClass.INVALID_FLAG_NAME.UNCONFIRM : constClass.INVALID_FLAG_NAME[r.invalid_flag],
        invalid_cause: r.invalid_cause ? constClass.INVALID_CAUSE_NAME[r.invalid_cause] : '',
        invalid_date: r.invalid_date ? moment(r.invalid_date).format("YYYY/MM/DD HH:mm:ss") : ''
      };
      list.push(receipt);
    });

    setCsvData({ header: head, list: list });
  }, []);

  const refreshReceipt = useCallback(async () => {
    if (setting) {
      // useCallback対応のため検索ボタンを押したときのみ実行
      if (searchFlag) {
        const jwt = localStorage.getItem('jwt');

        // レシート検索データ
        const params = {
          site_id: siteId
        };
        if (shopId) {
          params.shop_id = shopId.value;
        }
        if (purchaseStartDate) {
          params.purchase_start_date = moment(purchaseStartDate).format('YYYYMMDD');
        }
        if (purchaseEndDate) {
          params.purchase_end_date = moment(purchaseEndDate).format('YYYYMMDD');
        }
        if (cashierNo) {
          params.cashier_no = cashierNo;
        }
        if (receiptNo) {
          params.receipt_no = receiptNo;
        }
        if (customerId) {
          params.customer_id = customerId;
        }
        if (scanStartDate) {
          params.scan_start_date = moment(scanStartDate).format('YYYYMMDD');
        }
        if (scanEndDate) {
          params.scan_end_date = moment(scanEndDate).add(1, 'days').startOf('day').format('YYYYMMDD');;
        }
        if (invalidFlag) {
          params.invalid_flag = invalidFlag.value;
        }
        if (invalidCause) {
          params.invalid_cause = invalidCause.value;
        }
        // レシートデータ
        const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receiptscan/search/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;
        setReceiptData(data);
        createCsvData(data, shopMaster);
      }
      setSearchFlag(false);
    }
  }, [searchFlag, createCsvData, setting, siteId, shopId, purchaseStartDate, purchaseEndDate, cashierNo, receiptNo, customerId, shopMaster, scanStartDate, scanEndDate, invalidFlag, invalidCause]);

  const refreshMaster = useCallback(async () => {
    if (setting) {
      const jwt = localStorage.getItem('jwt');

      // お客様詳細マスタ
      const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/${siteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      setShopMaster(shop);
    }
  }, [setting, siteId]);

  const handleHeaderClick = (column) => {
    var _sortOrder = sortOrder.slice();
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    // var data = receiptData.slice();
    // data.sort((a, b) => sortData(a, b));
    // console.log(data);
    // setReceiptData(data);
  }

  /* 無効処理 */
  const handleInvalidClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`以下のレシート読込を無効にします。よろしいですか？\n${receiptDescription(data)}`)) {
      return;
    }
    const params = {
      ...data,
      invalid_flag: constClass.INVALID_FLAG.INVALID,
      invalid_cause: constClass.INVALID_CAUSE.OTHER
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receiptscan/invalid_update/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && !res.data.result) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshReceipt();
      setLockData(false);
    }
  }

  /* 返品処理 */
  const handleRefundClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`以下のレシート読込を返品済みとして無効にします。よろしいですか？\n${receiptDescription(data)}`)) {
      return;
    }
    const params = {
      ...data,
      invalid_flag: constClass.INVALID_FLAG.INVALID,
      invalid_cause: constClass.INVALID_CAUSE.RETURNED
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receiptscan/invalid_update/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && !res.data.result) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshReceipt();
      setLockData(false);
    }
  }

  /* 無効取消処理 */
  const handleRegisterClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`以下のレシート読込の無効を取り消して有効にします。よろしいですか？\n${receiptDescription(data)}`)) {
      return;
    }
    const params = {
      ...data,
      invalid_flag: data.amount === null ? constClass.INVALID_FLAG.UNCONFIRM : constClass.INVALID_FLAG.VALID,
      invalid_cause: null
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receiptscan/invalid_update/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
      if (res && !res.data.result) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      }
      else {
        addToast('更新しました。', { appearance: 'success', autoDismiss: true });
      }
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      setSearchFlag(true);
      await refreshReceipt();
      setLockData(false);
    }
  }

  const receiptDescription = (data) => {
    return `お客様No：${data.customer_id}\n店舗：${getShop(data, shopMaster).shop_name} (${getShop(data, shopMaster).shop_code})\n購入日：${data.purchase_date}\nレジNo：${data.cashier_no}\nレシートNo：${data.receipt_no}`
  }

  // const validator = (name, value) => {
  //   switch (name) {
  //     case 'birth-y':
  //       return yearValidation(value);
  //     case 'postalCode':
  //       return postalCodeValidation(value);
  //     default:
  //       return '';
  //   }
  // }

  // const yearValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{4}$/;
  //   if (!regex.test(value)) return '※正しい形式で年を入力してください';
  //   return '';
  // }

  // const postalCodeValidation = (value) => {
  //   if (value === '') return '';
  //   const regex = /^[0-9]{0,7}$/;
  //   if (!regex.test(value)) return '※正しい形式で郵便番号を入力してください';
  //   return '';
  // }

  const sortData = (a, b) => {
    if (sortOrder.length <= 0) { // 初期値は購入日降順・レジNo・レシートNo
      return Number(b.purchase_date) - Number(a.purchase_date) === 0 ? (Number(a.cashier_no) - Number(b.cashier_no) === 0 ? (Number(a.receipt_no) - Number(b.receipt_no)) : Number(a.cashier_no) - Number(b.cashier_no)) : Number(b.purchase_date) - Number(a.purchase_date);
    }
    for (var i = 0; i < sortOrder.length; i++) {
      const item = sortOrder[i];
      if (item.column === 'point') {
        const aData = a.t_points ? Number(a.t_points.point) : 0;
        const bData = b.t_points ? Number(b.t_points.point) : 0;
        if (item.order === 'ASC') {
          if (aData < bData) return -1;
          if (aData > bData) return 1;
        } else {
          if (bData < aData) return -1;
          if (bData > aData) return 1;
        }
      } else if (item.column === 'cashier_no' || item.column === 'receipt_no') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)} key={column}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span key={idx}>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  const renderInvalidButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1`}
        disabled={lockData}
        onClick={() => handleInvalidClick(data)}>
        無効
      </button>
    )
  }

  const renderRefundButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1`}
        disabled={lockData}
        onClick={() => handleRefundClick(data)}>
        返品
      </button>
    )
  }

  const renderRegisterButton = (data) => {
    return (
      <button
        className={`btn btn-secondary mx-1 px-0`}
        disabled={lockData}
        onClick={() => handleRegisterClick(data)}>
        返品・無効取消
      </button>
    )
  }

  const getShop = (data, shopMaster) => {
    return data.shop_id && shopMaster && shopMaster.find(s => s.shop_id === data.shop_id) ? shopMaster.find(s => s.shop_id === data.shop_id) : {}
  }

  useEffect(() => {
    refreshReceipt();
  }, [refreshReceipt]);

  useEffect(() => {
    refreshMaster();
  }, [refreshMaster]);

  return (
    <div className="container">
      <div className="row mx-0 my-2 px-1-env">
        <div className="col-2 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>お客様No</span>
            </div>
            <div className="col text-center">
              <input className={`form-control`} type="text" id={'customerId'} name={'customerId'} value={customerId} onChange={(e) => setCustomerId(e.target.value)}></input>
            </div>
          </div>
        </div>
        <div className="col-3 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>店舗</span>
            </div>
            <div className="col text-center">
              <Select
                styles={{ container: (base) => ({ ...base, zIndex: 600 }) }}
                options={shopMaster ? shopMaster.map(s => ({ value: s.shop_id, label: `${s.shop_name} (${s.shop_code})` })) : []}
                isClearable={true}
                closeMenuOnSelect={true}
                value={shopId || {}}
                onChange={(value) => setShopId(value)} />
            </div>
          </div>
        </div>
        <div className="col-3 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>購入日</span>
            </div>
            <div className="col pr-0 text-center">
              <DatePicker className="custom-select text-left"
                locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id={'purchaseStartDate'} name={'purchaseStartDate'}
                popperPlacement='bottom'
                selected={purchaseStartDate}
                onChange={(value) => setPurchaseStartDate(value)}
                placeholderText="- 選択 -"
                autoComplete='off'
                isClearable={true}
              />
            </div>
            <div className="col-auto px-0 text-center align-self-center">
              <span>～</span>
            </div>
            <div className="col pl-0 text-center">
              <DatePicker className="custom-select text-left"
                locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id={'purchaseEndDate'} name={'purchaseEndDate'}
                popperPlacement='bottom'
                selected={purchaseEndDate}
                onChange={(value) => setPurchaseEndDate(value)}
                placeholderText="- 選択 -"
                autoComplete='off'
                isClearable={true}
              />
            </div>
            {/*インプット*/}
            {/* <div className="col pr-0 text-center">
                  <input className={`form-control`} type="number" id={'purchaseStartDate'} name={'purchaseStartDate'} value={purchaseStartDate} onChange={(e) => setPurchaseStartDate(e.target.value)} max={9999 / 12 / 31}></input>
                </div>
                <div className="col-auto px-0 text-center align-self-center">
                  <span>～</span>
                </div>
                <div className="col pl-0 text-center">
                  <input className={`form-control`} type="number" id={'purchaseEndDate'} name={'purchaseEndDate'} value={purchaseEndDate} onChange={(e) => setPurchaseEndDate(e.target.value)} max={9999 / 12 / 31}></input>
                </div> */}
          </div>
        </div>
        <div className="col-2 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>レジNo</span>
            </div>
            <div className="col text-center">
              <input className={`form-control`} type="text" id={'cashierNo'} name={'cashierNo'} value={cashierNo} onChange={(e) => setCashierNo(e.target.value)}></input>
            </div>
          </div>
        </div>
        <div className="col-2 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>レシートNo</span>
            </div>
            <div className="col text-center">
              <input className={`form-control`} type="text" id={'receiptNo'} name={'receiptNo'} value={receiptNo} onChange={(e) => setReceiptNo(e.target.value)}></input>
            </div>
          </div>
        </div>
        <div className="col-1 text-center">
        </div>
      </div>
      <div className="row mx-0 my-2 px-1-env">
        <div className="col-3 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>読込日</span>
            </div>
            <div className="col pr-0 text-center">
              <DatePicker className="custom-select text-left"
                locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id={'scanStartDate'} name={'scanStartDate'}
                popperPlacement='bottom'
                selected={scanStartDate}
                onChange={(value) => setScanStartDate(value)}
                placeholderText="- 選択 -"
                autoComplete='off'
                isClearable={true}
              />
            </div>
            <div className="col-auto px-0 text-center align-self-center">
              <span>～</span>
            </div>
            <div className="col pl-0 text-center">
              <DatePicker className="custom-select text-left"
                locale="ja" dateFormat="yyyyMMdd" monthsShown="1" id={'scanEndDate'} name={'scanEndDate'}
                popperPlacement='bottom'
                selected={scanEndDate}
                onChange={(value) => setScanEndDate(value)}
                placeholderText="- 選択 -"
                autoComplete='off'
                isClearable={true}
              />
            </div>
          </div>
        </div>
        <div className="col-3 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>無効フラグ</span>
            </div>
            <div className="col text-center">
              <Select
                styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                options={[
                  { value: constClass.INVALID_FLAG.INVALID, label: constClass.INVALID_FLAG_NAME.INVALID },
                  { value: constClass.INVALID_FLAG.VALID, label: constClass.INVALID_FLAG_NAME.VALID },
                  { value: 3, label: constClass.INVALID_FLAG_NAME.UNCONFIRM },
                ]}
                isClearable={true}
                closeMenuOnSelect={true}
                value={invalidFlag || {}}
                onChange={(value) => setInvalidFlag(value)} />
            </div>
          </div>
        </div>
        <div className="col-3 px-0 mx-0">
          <div className="row px-0 mx-0">
            <div className="col-auto pr-0 pl-1 text-center align-self-center">
              <span>無効理由</span>
            </div>
            <div className="col text-center">
              <Select
                styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                options={[
                  { value: constClass.INVALID_CAUSE.UNMATCH, label: constClass.INVALID_CAUSE_NAME.UNMATCH },
                  { value: constClass.INVALID_CAUSE.RET_RCPT, label: constClass.INVALID_CAUSE_NAME.RET_RCPT },
                  { value: constClass.INVALID_CAUSE.AMOUNT, label: constClass.INVALID_CAUSE_NAME.AMOUNT },
                  { value: constClass.INVALID_CAUSE.RETURNED, label: constClass.INVALID_CAUSE_NAME.RETURNED },
                  { value: constClass.INVALID_CAUSE.OTHER, label: constClass.INVALID_CAUSE_NAME.OTHER },
                ]}
                isClearable={true}
                closeMenuOnSelect={true}
                value={invalidCause || {}}
                onChange={(value) => setInvalidCause(value)} />
            </div>
          </div>
        </div>
        <div className="col-2 px-0 mx-0">
        </div>
        <div className="col-1 text-center">
          <button type="button"
            className={`btn btn-primary mx-1 w-100`}
            onClick={() => setSearchFlag(true)}>
            検索</button>
        </div>
      </div>
      {receiptData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {receiptData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-10 pl-0 text-left align-self-end">
              <div className="row mr-0">
                <div className="col-2 pr-2 align-self-center">
                  {receiptData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          一覧表印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2 align-self-center">
                  {receiptData[0] && csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list.sort((a, b) => sortData(a, b))} headers={csvData.header} filename={"レシート一覧.csv"}>
                      一覧表CSV出力
                    </CSVLink>
                  }
                </div>
                <div className="col-2 px-2 align-self-center">
                  {/* {receiptData[0] && lineTextData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={lineTextData.list} filename={"LINE通知用ユーザID一覧.csv"} enclosingCharacter={""}>
                      ユーザID出力
                    </CSVLink>
                  } */}
                </div>
              </div>
            </div>
            <div className="col-2 px-2 align-self-center text-right border h-100">
              <div className="row">
                <div className="col-auto py-1 px-2 bg-lightwhite h-100">一覧表件数</div>
                <div className="col py-1">{receiptData.length.toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <InfiniteScroll
                loadMore={(p) => setPage(Number(p) * pageCount)}    //項目を読み込む際に処理するコールバック関数 
                hasMore={(receiptData.length > page)}         //読み込みを行うかどうかの判定
                loader={<div key={-1}>{receiptData.length}件中 {page + pageCount}件まで表示中（スクロールで読み込み）</div>}       //読み込み最中に表示する項目
              >
                <table className="table table-bordered table-striped" height="1">
                  <thead className={`table-info`}>
                    <tr>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('shop_id', [])}>
                        お客様No
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('shop_id', [])}>
                        店舗
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('purchase_date', [])}>
                        購入日
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('cashier_no', [])}>
                        レジNo
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('receipt_no', [])}>
                        レシートNo
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('scan_date', [])}>
                        読込日時
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('point', [])}>
                        獲得ポイント
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('point', [])}>
                        金額
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('invalid_flag', [])}>
                        無効フラグ
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('invalid_cause', [])}>
                        無効理由
                      </th>
                      <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('invalid_date', [])}>
                        無効日時
                      </th>
                      {/* {Common.inputList(setting, customerDetailMaster).map(i =>
                        <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick(i.entry_type, i.detail_data)} key={i.entry_type}>
                          {i.entry_type_short_name}
                        </th>
                      )} */}
                      <th className="text-center align-middle print-none">
                        処理
                      </th>
                    </tr>
                    <tr className="print-none">
                      {renderSortTh('customer_id')}
                      {renderSortTh('shop_id')}
                      {renderSortTh('purchase_date')}
                      {renderSortTh('cashier_no')}
                      {renderSortTh('receipt_no')}
                      {renderSortTh('scan_date')}
                      {renderSortTh('point')}
                      {renderSortTh('amount')}
                      {renderSortTh('invalid_flag')}
                      {renderSortTh('invalid_cause')}
                      {renderSortTh('invalid_date')}
                      {/* {Common.inputList(setting, customerDetailMaster).map(i =>
                        renderSortTh(i.entry_type)
                      )} */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptData.sort((a, b) => sortData(a, b)).map((data, idx) => (
                      <tr key={data.customer_id + data.point_date}>
                        <td className="text-center align-middle p-1">
                          <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{data.customer_id}</Link>
                        </td>
                        <td className="text-center align-middle p-1">
                          {getShop(data, shopMaster).shop_name + ' (' + getShop(data, shopMaster).shop_code + ')'}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.purchase_date}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.cashier_no}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.receipt_no}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.scan_date ? moment(data.scan_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.t_points ? data.t_points.point : ''}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.amount}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.invalid_flag === constClass.INVALID_FLAG.UNCONFIRM ? constClass.INVALID_FLAG_NAME.UNCONFIRM : constClass.INVALID_FLAG_NAME[data.invalid_flag]}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.invalid_cause ? constClass.INVALID_CAUSE_NAME[data.invalid_cause] : ''}
                        </td>
                        <td className="text-center align-middle p-1">
                          {data.invalid_date ? moment(data.invalid_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                        </td>
                        {/* {Common.inputList(setting, customerDetailMaster).map(i => {
                          return <td className="text-center align-middle p-1" key={i.entry_type + data.customer_id + data.shop_id + data.purchase_date + data.cashier_no + data.receipt_no}>
                            {i.entry_type === 'customer_id' && <Link to={`${generatePath(`${props.match.path}`, { siteId })}customer/${data.customer_id}`}>{renderData(data.t_customer, i)}</Link>}
                            {i.entry_type !== 'customer_id' && renderData(data.t_customer, i)}
                          </td>
                        })} */}
                        {/* 処理 */}
                        <td className="text-center text-nowrap align-middle p-1 print-none">
                          {data.invalid_flag !== constClass.INVALID_FLAG.INVALID && renderRefundButton(data)}
                          {data.invalid_flag !== constClass.INVALID_FLAG.INVALID && renderInvalidButton(data)}
                          {data.invalid_flag === constClass.INVALID_FLAG.INVALID && renderRegisterButton(data)}
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomerReceipt;